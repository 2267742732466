<template>
    <div class="home-stage">
        <div class="main-stage">
            <div class="main-stage__container">
                <div class="swiper-button-prev">
                    <chevron-left-icon />
                </div>
                <div class="swiper-button-next">
                    <chevron-right-icon />
                </div>

                <swiper
                    ref="homeStageSwiper"
                    :options="swiperOptions"
                    class="slide__container"
                    @slideChange="onSlideChange"
                >
                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Der digitale Rundumservice für Ihr
                                Fotofachgeschäft / Fotostudio
                            </div>
                            <div class="slide__content-text">
                                Entdecken Sie unsere digitalen Tools wie
                                Bilderupload-Service, Galeriefunktion,
                                Cross-Selling, Kiosk oder Ordermanager, die
                                Ihnen dabei helfen, Kunden zu binden, die
                                Reichweite zu erhöhen und den Umsatz zu
                                steigern.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--shop"
                                src="~/assets/shop/stage.png"
                                alt="Shop"
                            />
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Umsatzplus mit Produkt-Paketen
                            </div>
                            <div class="slide__content-text">
                                Steigern Sie Ihren Umsatz: Kreieren Sie mühelos
                                Produkt-Pakete mit attraktiven Rabatten. Erhöhen
                                Sie jetzt den durchschnittlichen Bestellwert!
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--designer slide__media-image--with-shadow slide__media-image--with-border-radius"
                                src="~/assets/shop/pakete.jpg"
                                alt="Pakete"
                            />
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Verkaufen Sie Ihre Fotos
                            </div>
                            <div class="slide__content-text">
                                Wir bieten Ihnen eine integrierte Lösung zum
                                Verkauf Ihrer Fotos, die Sie auf
                                Schulveranstaltungen, Kommunionen, Sportevents,
                                Hochzeiten oder anderen Ereignissen aufgenommen
                                haben. So können Sie Ihr Fotogeschäft einfach
                                und effizient erweitern.
                            </div>
                            <nuxt-link
                                to="/galerie"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--shop"
                                src="~/assets/gallery/stage.png"
                                alt="Galerie"
                            />
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Warenwertgutscheine
                            </div>
                            <div class="slide__content-text">
                                Schaffen Sie mit unseren Gutscheinen, die in
                                verschiedenen Motiven und Werten erhältlich
                                sind, eine zusätzliche Kundenbindung. Ihre
                                Kunden können ihren Lieben zu jeder Gelegenheit
                                das passende Geschenk machen.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--coupon slide__media-image--with-shadow slide__media-image--with-border-radius"
                                src="~/assets/shop/coupon.jpg"
                                alt="Verkaufen Sie Gutscheine mit verschiedenen Motiven und definierten Gutscheinwerten"
                            />
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Verkaufen Sie passende Zusatzprodukte
                            </div>
                            <div class="slide__content-text">
                                Bieten Sie Ihren Kunden die Möglichkeit,
                                passende Zusatzartikel zu ihren Fotoprodukten
                                wie Poster oder Leinwänden im Warenkorb
                                hinzuzufügen und steigern Sie so den Umsatz.
                                Entscheiden Sie selbst, bei welchen Produkten
                                diese Option angezeigt werden soll.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--crossselling slide__media-image--with-shadow slide__media-image--with-border-radius"
                                src="~/assets/shop/cross-selling.jpg"
                                alt="Verkaufen Sie passende Zusatzprodukte"
                            />
                        </div>
                    </swiper-slide>

                    <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Geschenke designen lassen
                            </div>
                            <div class="slide__content-text">
                                Verkaufen Sie Geschenkartikel wie Tassen,
                                T-Shirts oder Smartphone-Hüllen und ermöglichen
                                Sie Ihren Kunden eine Live-Vorschau, um das
                                Ergebnis direkt zu sehen.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--designer slide__media-image--with-shadow slide__media-image--with-border-radius"
                                src="~/assets/home/designer.jpg"
                                alt="Geschenkedesigner"
                            />
                        </div>
                    </swiper-slide>

                    <!-- <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Bilder schneller hochladen und Zeit sparen
                            </div>
                            <div class="slide__content-text">
                                Durch die automatische Verkleinerung der
                                Bildergröße auf jedem Endgerät wird die
                                Übertragung von Bildern auf unseren Server
                                schnell und einfach - ohne Qualitätsverlust.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--client-resize"
                                src="~/assets/shop/client-resize.png"
                                alt="Bilder schneller hochladen und Zeit sparen"
                            />
                        </div>
                    </swiper-slide> -->

                    <!-- <swiper-slide class="slide">
                        <div class="slide__content">
                            <div class="slide__content-headline">
                                Moderne Bezahlmethoden
                            </div>
                            <div class="slide__content-text">
                                Nutzen Sie klassische Bezahloptionen wie
                                Vorkasse, Nachnahme, Rechnung oder Barzahlung
                                und bieten Sie Ihren Kunden zudem moderne
                                Online-Bezahldienste wie PayPal, Klarna und
                                Sofortüberweisung an, um den Bestellprozess zu
                                erleichtern.
                            </div>
                            <nuxt-link
                                to="/online-shop"
                                class="slide__content-button"
                            >
                                Mehr erfahren
                            </nuxt-link>
                        </div>

                        <div class="slide__media">
                            <img
                                class="slide__media-image slide__media-image--payment slide__media-image--with-shadow slide__media-image--with-border-radius"
                                src="~/assets/home/payment.jpg"
                                alt="Moderne Bezahlmethoden"
                            />
                        </div>
                    </swiper-slide> -->
                </swiper>
            </div>
        </div>
        <div class="sub-stage">
            <div class="sub-stage__container">
                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 1,
                        },
                    ]"
                    @click="swiper.slideTo(1)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image"
                            src="~/assets/shop/stage.png"
                            alt="Shop"
                        />
                    </div>
                    <div class="sub-stage__item-title">Rundumservice</div>
                </div>

                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 2,
                        },
                    ]"
                    @click="swiper.slideTo(2)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image sub-stage__item-image--with-shadow sub-stage__item-image--with-border-radius"
                            src="~/assets/shop/pakete.jpg"
                            alt="Pakete"
                        />
                    </div>
                    <div class="sub-stage__item-title">Produkt-Pakete</div>
                </div>

                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 3,
                        },
                    ]"
                    @click="swiper.slideTo(3)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image"
                            src="~/assets/gallery/stage.png"
                            alt="Galerie"
                        />
                    </div>
                    <div class="sub-stage__item-title">Galerie</div>
                </div>

                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 4,
                        },
                    ]"
                    @click="swiper.slideTo(4)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image sub-stage__item-image--with-shadow sub-stage__item-image--with-border-radius"
                            src="~/assets/shop/coupon.jpg"
                            alt="Verkaufen Sie Gutscheine mit verschiedenen Motiven und definierten Gutscheinwerten"
                        />
                    </div>
                    <div class="sub-stage__item-title">Gutscheine</div>
                </div>

                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 5,
                        },
                    ]"
                    @click="swiper.slideTo(5)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image sub-stage__item-image--with-shadow sub-stage__item-image--with-border-radius"
                            src="~/assets/shop/cross-selling.jpg"
                            alt="Verkaufen Sie passende Zusatzprodukte"
                        />
                    </div>
                    <div class="sub-stage__item-title">Cross-Selling</div>
                </div>

                <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 6,
                        },
                    ]"
                    @click="swiper.slideTo(6)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image sub-stage__item-image--with-shadow sub-stage__item-image--with-border-radius"
                            src="~/assets/home/designer.jpg"
                            alt="Shop"
                        />
                    </div>
                    <div class="sub-stage__item-title">Geschenkedesigner</div>
                </div>

                <!-- <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 6,
                        },
                    ]"
                    @click="swiper.slideTo(6)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image"
                            src="~/assets/shop/client-resize.png"
                            alt="Bilder schneller hochladen und Zeit sparen"
                        />
                    </div>
                    <div class="sub-stage__item-title">Client-Resize</div>
                </div> -->

                <!-- <div
                    :class="[
                        'sub-stage__item',
                        {
                            'sub-stage__item--active': currentSlide === 7,
                        },
                    ]"
                    @click="swiper.slideTo(7)"
                >
                    <div class="sub-stage__item-image-container">
                        <img
                            class="sub-stage__item-image"
                            src="~/assets/home/payment.jpg"
                            alt="Bilder schneller hochladen und Zeit sparen"
                        />
                    </div>
                    <div class="sub-stage__item-title">Bezahlmethoden</div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';

export default {
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            currentSlide: 1,
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 7500,
                    disableOnInteraction: false,
                },
                speed: 1000,
                wrapperClass: 'slide__wrapper',
                slideClass: 'slide',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    992: {
                        direction: 'vertical',
                    },
                },
            },
        };
    },

    computed: {
        swiper() {
            return this.$refs.homeStageSwiper.$swiper;
        },
    },

    methods: {
        onSlideChange() {
            if (this.swiper.activeIndex === this.swiper.slides.length - 1) {
                this.currentSlide = 1;
            } else {
                this.currentSlide = this.swiper.activeIndex;
            }
        },
    },
};
</script>

<style lang="scss">
.main-stage {
    background-color: $light_blue_03;

    &__container {
        position: relative;

        @include breakpoint(sm) {
            @include container;
        }
    }
}

.sub-stage {
    background-color: $light_blue_02;
    padding: 30px 0 15px;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__item {
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 6px;
        margin-left: 15px;
        margin-bottom: 15px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 45%;
        height: 85px;
        transition: 0.3s all;
        cursor: pointer;

        &:nth-child(2n - 1) {
            margin-left: 0;
        }

        @include breakpoint(xs) {
            width: 30%;

            &:nth-child(2n - 1) {
                margin-left: 15px;
            }

            &:nth-child(1),
            &:nth-child(4) {
                margin-left: 0;
            }
        }

        @include breakpoint(sm) {
            width: 120px;

            &:nth-child(2n - 1) {
                margin-left: 0;
            }

            &:nth-child(4),
            &:nth-child(2n - 1):not(:first-child) {
                margin-left: 15px;
            }
        }

        &:hover {
            opacity: 0.7;
            background-color: white;
        }

        &-image {
            max-width: 55px;
            max-height: 35px;
            width: auto;
            height: auto;

            &--with-shadow {
                box-shadow: 0px 0px 10px rgba(54, 65, 75, 0.1);
            }

            &--with-border-radius {
                border-radius: 3px;
            }

            &-container {
                width: 55px;
                height: 40px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 11px;
            line-height: 14px;
            text-align: center;
            color: $dark_blue_01;
            margin-top: 5px;
        }

        &--active {
            background-color: white;
            box-shadow: 0px 0px 20px rgba(54, 65, 75, 0.2);
        }
    }
}

.swiper-button-next {
    position: absolute;
    z-index: 8;
    top: 50%;
    right: -75px;
    width: 35px;
    height: 35px;
    transform: translateY(-50%) rotate(90deg);
    transition: 0.3s all;
    cursor: pointer;
    background-color: rgba(164, 175, 188, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @media (max-width: 1230px) {
        display: none;
    }

    &:hover {
        opacity: 0.75;
    }

    svg {
        stroke: $dark_blue_02;
        max-width: 20px;
        max-height: 20px;
        width: auto;
        height: auto;
    }
}

.swiper-button-prev {
    @extend .swiper-button-next;
    transform: translateY(-50%) rotate(90deg);
    right: auto;
    left: -75px;
}

.slide {
    box-sizing: border-box;
    background-color: $light_blue_03;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint(xs) {
        flex-wrap: unset;
        padding: 60px 20px;
    }

    @include breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
    }

    &__content {
        order: 2;
        width: 100%;
        text-align: center;

        @include breakpoint(xs) {
            max-width: 48%;
            padding-right: 80px;
            text-align: left;
        }

        &-headline {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
            color: $dark_blue_01;

            @include breakpoint(sm) {
                font-size: 28px;
                line-height: 34px;
            }
        }

        &-text {
            margin-top: 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: $dark_blue_02;

            @include breakpoint(sm) {
                font-size: 16px;
                line-height: 26px;
            }
        }

        &-button {
            margin-top: 25px;
            display: inline-flex;
            align-items: center;
            background-color: $red;
            border-radius: 3px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            padding: 8px 14px;
            color: white;
            transition: 0.3s all;
            cursor: pointer;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    &__media {
        order: 1;
        width: 100%;
        text-align: center;
        border-radius: 3px;

        @include breakpoint(xs) {
            text-align: right;
            order: 2;
        }

        &-image {
            max-width: 500px;
            max-height: 360px;
            width: auto;
            height: auto;

            &--with-shadow {
                margin-right: 30px;
                box-shadow: 0px 0px 30px rgba(54, 65, 75, 0.2);
            }

            &--with-border-radius {
                border-radius: 3px;
            }

            &--shop {
                max-width: 640px;
                max-height: 440px;

                @media (max-width: 992px) {
                    margin-right: 0;
                    max-height: 200px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-height: 235px;
                    margin-bottom: 15px;
                }

                @media (max-width: 420px) {
                    max-width: 100%;
                }
            }

            &--coupon {
                max-height: 340px;

                @media (max-width: 992px) {
                    max-height: 250px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-height: 220px;
                    margin-bottom: 30px;
                }

                @media (max-width: 420px) {
                    max-width: 70%;
                }
            }

            &--crossselling {
                max-height: 340px;

                @media (max-width: 992px) {
                    max-height: 300px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-height: 250px;
                    margin-bottom: 30px;
                }

                @media (max-width: 420px) {
                    max-width: 70%;
                }
            }

            &--designer {
                max-width: 450px;

                @media (max-width: 992px) {
                    max-width: 350px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-width: 250px;
                    margin-bottom: 30px;
                }

                @media (max-width: 420px) {
                    max-width: 80%;
                }
            }

            &--client-resize {
                max-height: 580px;
                margin-bottom: -160px;

                @media (max-width: 992px) {
                    max-height: 450px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-width: 250px;
                    margin-bottom: 15px;
                }

                @media (max-width: 420px) {
                    max-width: 70%;
                }
            }

            &--payment {
                max-height: 320px;

                @media (max-width: 992px) {
                    max-height: 250px;
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                    max-height: 220px;
                    margin-bottom: 30px;
                }

                @media (max-width: 420px) {
                    max-width: 70%;
                }
            }
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @include breakpoint(xs) {
            height: 500px;
        }

        @include breakpoint(sm) {
            align-items: flex-start;
            display: block;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;
    }
}
</style>
