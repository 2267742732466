<template>
    <div class="feedback">
        <div class="feedback__container">
            <section-headline>
                <template slot="icon"><user-check-icon /></template>
                <template slot="text">Endkundenerfahrungen</template>
            </section-headline>

            <div v-swiper="swiperOptions" class="item__container">
                <div class="feedback-swiper-button-prev">
                    <chevron-left-icon />
                </div>
                <div class="feedback-swiper-button-next">
                    <chevron-right-icon />
                </div>
                <div class="item__wrapper">
                    <div class="item">
                        <div class="item__quote">
                            Inzwischen ist das Foto bestellen per Internet
                            wirklich sehr sehr einfach geworden! Man muss kein
                            Programm mehr auf dem Computer installieren und die
                            Bestellung ist ratzfatz getätigt. Dazu den guten
                            Service, den man aus dem Laden kennt! Ich bin immer
                            noch begeistert! Danke!
                        </div>
                        <div class="item__author">Firma K. - via Desktop</div>
                    </div>

                    <div class="item">
                        <div class="item__quote">
                            Ich bin von diesem Feature hellauf begeistert. Es
                            ist so einfach zu bedienen und selbsterklärend, dass
                            eine zusätzliche Beschreibung völlig überflüssig
                            ist. Toll ist auch, dass ich mich nicht extra
                            registrieren muss. Danke dafür
                        </div>
                        <div class="item__author">Frau L. - via Smartphone</div>
                    </div>

                    <div class="item">
                        <div class="item__quote">
                            Selten so einfach über das Internet bestellt!
                        </div>
                        <div class="item__author">Herr O. - via Tablet</div>
                    </div>

                    <div class="item">
                        <div class="item__quote">
                            Selten so einfach über das Internet bestellt!
                        </div>
                        <div class="item__author">Herr O. - via Tablet</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { directive } from 'vue-awesome-swiper';
import {
    UserCheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from 'vue-feather-icons';
import SectionHeadline from '~/components/section-headline.vue';

export default {
    components: {
        SectionHeadline,
        UserCheckIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
    },

    directives: {
        swiper: directive,
    },

    data() {
        return {
            swiperOptions: {
                loop: true,
                wrapperClass: 'item__wrapper',
                slideClass: 'item',
                navigation: {
                    nextEl: '.feedback-swiper-button-next',
                    prevEl: '.feedback-swiper-button-prev',
                },
                spaceBetween: 60,
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                    },
                    767: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.feedback {
    background-color: $light_blue_03;
    padding: 40px 0;

    @include breakpoint(xs) {
        padding: 80px 0;
    }

    &__container {
        @include container;
    }
}

.feedback-swiper-button-next {
    position: absolute;
    z-index: 8;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: 0.3s all;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }

    svg {
        stroke: $dark_blue_02;
        width: 30px;
        height: 30px;
    }
}

.feedback-swiper-button-prev {
    @extend .feedback-swiper-button-next;
    right: auto;
    left: 0;
}

.item {
    flex-shrink: 0;
    padding: 30px 0;

    @include breakpoint(xs) {
        padding: 0;
    }

    &__quote {
        background: #ffffff;
        box-shadow: 0px 0px 25px rgba(54, 65, 75, 0.07);
        border-radius: 6px;
        padding: 30px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $dark_blue_02;
        text-align: center;
    }

    &__author {
        margin-top: 25px;
        text-align: center;
        font-size: 13px;
        line-height: 20px;
        color: $dark_blue_02;
    }

    &__wrapper {
        display: flex;
        align-items: center;

        @include breakpoint(sm) {
            align-items: flex-start;
        }
    }

    &__container {
        margin-top: 15px;
        overflow: hidden;
        position: relative;
        padding: 0 50px;

        @include breakpoint(xs) {
            margin-top: 60px;
        }
    }
}
</style>
